import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo/seo"
import Layout from "../components/layout/layout"
import Cta from "../components/cta/cta"
import Clients from "../components/clients/clients"
import Hero from "../components/hero/heroSimple/heroSimple"
import Stories from "../components/stories/stories"

const CustomerStories = ({ data }) => {
  const { content, seo } = data.sanityCsListing.tabs

  return (
    <Layout>
      <Seo isBlogPost={false} title={content.title} {...seo} />
      <Hero title={content.hero} subtitle={content.subtitle} />
      <Stories stories={content.customerstories} />
      <Clients {...content.clients} />
      <Cta {...content.cta} background="blue" />
    </Layout>
  )
}

export const query = graphql`
  query {
    sanityCsListing {
      tabs {
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
        content {
          hero
          title
          subtitle
          cta {
            ...ctaFragment
          }
          customerstories {
            tabs {
              content {
                title
                slug {
                  current
                }
                logo {
                  asset {
                    fluid(maxWidth: 200) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              csListingPreview {
                description
                quote
                author
                logo {
                  asset {
                    fluid(maxWidth: 200) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
            }
          }
          clients {
            title
            client {
              logo {
                alt
                asset {
                  fluid(maxWidth: 200) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    allSanityCs {
      nodes {
        tabs {
          content {
            title
            slug {
              current
            }
            logo {
              asset {
                fluid(maxWidth: 200) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          csListingPreview {
            description
            quote
            author
            logo {
              asset {
                fluid(maxWidth: 200) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CustomerStories
