import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import FormattedLink from "../formattedLink/formattedLink"
import Wrapper from "../layout/wrapper/wrapper"

import styles from "./stories.module.scss"

const Stories = ({ stories }) => (
  <Wrapper>
    <div className="grid">
      <div className="gridItem small-12 medium-10 medium-offset-1">
        <div className={styles.grid}>
          {stories.map(story => (
            <div key={story.tabs.content.title} className={styles.item}>
              <div className={styles.front}>
                <div className={styles.inner}>
                  <Img
                    className={styles.image}
                    fluid={story.tabs?.csListingPreview?.logo?.asset.fluid || story.tabs.content.logo?.asset.fluid}
                    alt={story.logo?.alt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                  <p className={styles.text}>{story.tabs?.csListingPreview?.description}</p>
                  <p>
                    <FormattedLink type="link-pink" link={`customer-stories/${story.tabs.content.slug.current}`}>
                      Find out more
                    </FormattedLink>
                  </p>
                </div>
              </div>
              <div className={styles.back}>
                <div className={styles.inner}>
                  <div className={styles.quoteWrapper}>
                    <p className={styles.quote}>"{story.tabs?.csListingPreview?.quote}"</p>
                    <p className={styles.name}>- {story.tabs?.csListingPreview?.author}</p>
                  </div>
                  <p>
                    <FormattedLink type="link-white" link={`customer-stories/${story.tabs.content.slug.current}`}>
                      Find out more
                    </FormattedLink>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Wrapper>
)

export default Stories

Stories.propTypes = {
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      author: PropTypes.string,
      slug: PropTypes.object,
      logo: PropTypes.object,
    })
  ),
}
